import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Toggle`}</h1>
    <p>{`Toggle shows and hides a block of content.`}</p>
    <ComponentPreview componentName="toggle--default-story" hasReact hasAngular hasHTML titleAttr="Toggle Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use Toggle when you need to toggle the
visibility of a block of content. For
example, a disclaimer.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`By default, Toggle content is hidden,
so high-priority content should be avoided.`}</li>
      <li parentName="ul">{`If you are using several Toggle components
in succession, consider the
`}<a parentName="li" {...{
          "href": "/using-spark/components/accordion"
        }}>{`Accordion`}</a>{` component.`}</li>
      <li parentName="ul">{`The content within a Toggle should be kept
short and relevant to its context for maximum usability.`}</li>
      <li parentName="ul">{`Keep the text of the Toggle trigger short.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`A Toggle component must have an element that
triggers visibility of the Toggle content.`}</li>
      <li parentName="ul">{`A Toggle component must have a content area.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      